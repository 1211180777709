import * as React from 'react';
import PageSeo from '../../components/Seo/PageSeo';
import PageTitle from '../../components/PageTitle';
import styled from 'styled-components';
import PageSection from '../../components/PageSection';
import { ARDataTable, CalloutBanner, PageSeparator, TextBlock } from '../../components';
import ARTableMobile from '../../assets/images/AR-Profile-Table.svg';
import SingleARTableDesktop from '../../assets/images/single_table_desktop.svg';
import SingleARTableMobile from '../../assets/images/ar_single_table_mobile.svg';
import * as Text from '../../styles/shared/typography';
import ARTableDesktop from '../../assets/images/AR-Profile-Table-Desktop.png';

// SEO INFORMATION
const pageMeta = {
  title: 'Adverse Reactions for WELIREG™ (belzutifan)',
  keywords: 'welireg advanced renal cell carcinoma adverse reactions',
  description:  'Health care professionals can review adverse reactions in patients receiving WELIREG™ (belzutifan) in LITESPARK-005.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/safety/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/safety/","@type":"MedicalWebPage","name":"Safety Profile for WELIREG™ (belzutifan)","description":"The median duration of exposure for WELIREG was 7.6 months (range: 0.1–28.5 months). Serious ARs occurred in 38% of patients who received WELIREG. Permanent discontinuation of WELIREG due to ARs occurred in 6% of patients. ARs which resulted in permanent discontinuation (≥0.5%) of WELIREG were hypoxia (1.1%), anemia (0.5%), and hemorrhage (0.5%). Dosage interruptions of WELIREG due to an AR occurred in 39% of patients. Dose reductions of WELIREG due to an AR occurred in 13% of patients."}`   
  ]
}

const StandardSection = styled(PageSection)`
  display: flex;
  justify-content: center;
  padding: 0;
`;

const calloutBanner = {
  title: "Explore dosing information, including dose modifications, for WELIREG",
  bcbContinual: true,
  links: [
    {
      label: "Learn More",
      url: "/advanced-renal-cell-carcinoma/dosing",
    }
  ]
}

const ARDATA = [
  "The median duration of exposure for WELIREG was 7.6 months (range: 0.1–28.5 months)."
  ,"Serious ARs occurred in 38% of patients who received WELIREG."
  ,"Permanent discontinuation of WELIREG due to ARs occurred in 6% of patients. ARs which resulted in permanent discontinuation (≥0.5%) of WELIREG were hypoxia (1.1%), anemia (0.5%), and hemorrhage (0.5%)."
  ,"Dosage interruptions of WELIREG due to an AR occurred in 39% of patients."
  ,"Dose reductions of WELIREG due to an AR occurred in 13% of patients."
]

const ARFooter = {
  footnotes: [
    {
      label: 'a',
      text: 'Graded per NCI CTCAE v5.0.',
    },
    {
      label: 'b',
      text: 'Includes other related terms.',
    },
  ],
}

const RCCTable = styled(ARDataTable)`
flex-direction: column;
gap: 24px;

  & >img{
    max-width: 972px;
  }

  section {
  max-width: 100%;
  flex-direction: column;
  &>p {
    font-weight: 500;
    border-bottom: 1px solid rgba(248, 150, 33, 1) !important;
  }
}
`;

const SafetyHeader = styled(Text.H2)`
  font-weight: 400;;
  margin-bottom: 12px;
`;

const CustomTextBlock = styled(TextBlock)`
  [class*='NotationBlock__Definition']{
    margin-top: 0px;
  }
`;

const IndexPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/advanced-renal-cell-carcinoma/safety/" />
      </PageSeo>

      <PageTitle unbranded>
        Safety Profile for WELIREG™ (belzutifan)
      </PageTitle>

      <StandardSection>
        <PageSeparator topPadding='48px' topPaddingMobile='32px' />
      </StandardSection>

      <StandardSection>
        <RCCTable alt="Safety Profile of WELIREGTM (belzutifan): Adverse Reactions (≥10%) in Patients With Advanced RCC Receiving WELIREG in LITESPARK-005 Clinical Trial" body={ARDATA} imgDesktop={ARTableDesktop}  imgMobile={ARTableMobile} footer={ARFooter}/>
          
   
        <br/>
       <SafetyHeader> Select laboratory abnormalities in LITESPARK-005</SafetyHeader>
        <RCCTable alt="Select Laboratory Abnormalities (≥20) That Worsened From Baseline in Patients With Advanced RCC Who Received WELIREGTM (belzutifan) in LITESPARK-005 Clinical Trial" body={[]} imgDesktop={SingleARTableDesktop} imgMobile={SingleARTableMobile}/>

        <CustomTextBlock
          title=""
          footer={{ definitions: <><sup className='footnote-super'>a</sup>Each test incidence is based on the number of patients who had both baseline and at least one on-study laboratory measurement available: WELIREG (range: 359 to 366  patients) and everolimus (range: 351 to 356 patients).<br/><sup className='footnote-super'>b</sup>Graded per NCI CTCAE v5.0.<br/><br/>NCI CTCAE = National Cancer Institute Common Terminology Criteria for Adverse Events; AR = adverse reaction.</>}}
        />
      </StandardSection>
      <StandardSection>
        <PageSeparator topPadding='48px' topPaddingMobile='32px' />
      </StandardSection>

      <CalloutBanner {...calloutBanner} />

    </>
  );
};

export default IndexPage;


